import React from 'react'
import styled from 'styled-components'

import Layout from '../layouts'
import SEO from '../components/seo'
import BG from '../components/bg'

const StyledPerformance = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-between;
  padding: 0 2rem;
  padding-bottom: 4rem;
  margin-bottom: 4rem;
  padding-top: 2rem;

  border-bottom: 1px solid ${({ theme }) => theme.colors.grey2};

  @media (max-width: 960px) {
    flex-direction: column;
    grid-template-columns: 1fr;
    margin-top: 0rem;
    padding-top: 1rem;
  }

  @media (max-width: 560px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`

const StyledSectionTitle = styled.h3`
  max-width: 960px;
  line-height: 140%;
  font-size: 32px;
  @media (max-width: 640px) {
    text-align: left;
  }
`

const StyledBodyText = styled.div`
  font-size: 16px;
  margin-bottom: 1rem;
`

const Performance = props => {
    return (
        <Layout path={props.location.pathname}>
            <BG />
            
            <SEO title="Performance" path={props.location.pathname} />

            <StyledPerformance>
                <StyledSectionTitle>
                    Performance
                </StyledSectionTitle>
                <StyledBodyText>
                    Yes, we know why most of you will be attracted to Rissotto; hyper yields that you cannot even fathom from centralized finance (banks). There are different ways to earn yield on Rissotto offerings (To Be Announced soon) ranging from 10%-95% Annual Percentage Yields (APYs) for supplying liquidity
                </StyledBodyText>
                <StyledBodyText>
                    The yield from the Liquidity Pool (LP) is rewarded in RSOT.
                </StyledBodyText>
                <StyledBodyText>
                    When providing liquidity to these pools, it is crucial to understand how an Automatic Market Maker (AMM) works and the arbitrage opportunities it provides, putting the LP&apos;s at risk of Impermanent Loss (IL). This can be exceptionally risky when pooling un-correlated assets and have high volatility, so it is essential to understand these associated risks.
                </StyledBodyText>
                <StyledBodyText>
                    Yield can also be generated through staking RSOT tokens. RSOT can be staked in the pools. Staking proved extremely valuable at the start of Rissotto which we are expecting soaring APYs.
                </StyledBodyText>
                <StyledBodyText>
                    For AI machine trading, stop lost are automatically calculated real time, calibrated and triggered on the risk profile of tokens. As with every investment risk, while not 100% certainty, we have consistently maintain an average of ~89.7% success rate till date.
                </StyledBodyText>
            </StyledPerformance>
        </Layout>
    )
  
}

export default Performance  